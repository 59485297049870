import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useIntl } from "react-intl";
// eslint-disable-next-line
import momentDurationFormatSetup from "moment-duration-format";
import { withStyles } from "@material-ui/core";
import RateReviewIcon from "@material-ui/icons/RateReview";
import { formatTimer } from "../../utils/timer/timer";
import Point from "./Point";

Bar.propTypes = {
  disabled: PropTypes.bool,
  duration: PropTypes.number,
  curTime: PropTypes.number,
  onTimeUpdate: PropTypes.func,
  showAddCommentButton: PropTypes.bool,
  /** onClickAddCommentAt(curTime:number) */
  onClickAddCommentAt: PropTypes.func,
  commentsTimeMarks: PropTypes.arrayOf(PropTypes.number),
  multipleTimeMarks: PropTypes.bool,
};

Bar.defaultProps = {
  commentsTimeMarks: [],
};

function Bar({
  disabled,
  duration,
  curTime,
  onTimeUpdate,
  showAddCommentButton,
  onClickAddCommentAt,
  commentsTimeMarks,
  multipleTimeMarks,
  classes,
}) {
  const intl = useIntl();
  const curPercentage = (curTime / duration) * 100;

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar = document.getElementById("feedbackLigacaoAudioPlayer");
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = eMove => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener("mousemove", updateTimeOnMove);

    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", updateTimeOnMove);
    });
  }

  return (
    <span style={{ width: "100%", position: "relative" }}>
      {showAddCommentButton && (
        <span
          className={classes.addCommentButton}
          style={{ left: `calc(${curPercentage}%)`, top: -32 }}
          title={intl.formatMessage(
            { defaultMessage: `Adicionar Comentário no tempo {time}` },
            { time: formatTimer(curTime) }
          )}
          onClick={() => onClickAddCommentAt(curTime)}
        >
          <RateReviewIcon color="primary" />
        </span>
      )}
      <div
        id="feedbackLigacaoAudioPlayer"
        className={cn(classes.progressBar, { [classes.progressBarDisabled]: disabled })}
        style={{
          background: `linear-gradient(to right, rgba(0, 0, 0, 0.54) ${curPercentage}%, #ddd 0)`,
        }}
        onMouseDown={e => handleTimeDrag(e)}
        data-testid="progressBar"
      >
        {!multipleTimeMarks &&
          !disabled &&
          commentsTimeMarks?.map((timeMark, i) => (
            <span
              className={classes.timeMark}
              style={{ left: `calc(${(timeMark / duration) * 100}% - 2px)` }}
              key={i}
            />
          ))}
        {multipleTimeMarks &&
          !disabled &&
          commentsTimeMarks?.map((item, i) => <Point key={i} durationSeconds={duration} item={item} />)}
        {!disabled && (
          <span
            data-testid="progressBarKnob"
            className={cn(classes.progressBarKnob, {
              [classes.progessBarKnobHighlight]: commentsTimeMarks?.indexOf(curTime) >= 0,
            })}
            style={{ left: `calc(${curPercentage}% - ${multipleTimeMarks ? 5 : 2}px)` }}
          />
        )}
      </div>
    </span>
  );
}

export default withStyles(theme => ({
  root: {
    userSelect: "none",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  addCommentButton: {
    position: "absolute",
    width: 24,
    height: 24,
    borderRadius: 3,
    "&:hover": {
      cursor: "pointer",
    },
  },
  progressBar: {
    flex: 1,
    borderRadius: 5,
    height: 6,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  progressBarDisabled: {
    pointerEvents: "none",
    opacity: 0.4,
  },
  progressBarKnob: {
    position: "relative",
    height: 14,
    width: 14,
    border: "1px solid white",
    borderRadius: "50%",
    backgroundColor: "white",
    boxShadow: "0 0 4px rgba(0,0,0,0.5)",
  },
  progessBarKnobHighlight: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  timeMark: {
    position: "absolute",
    height: 14,
    width: 14,
    borderRadius: "50%",
    border: "1px solid white",
    backgroundColor: theme.palette.primary.main,
  },
}))(Bar);
