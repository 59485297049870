import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { Avatar, Typography, withStyles } from "@material-ui/core";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Done from "@material-ui/icons/Done";
import ReplyIcon from "@material-ui/icons/Reply";

import { getIniciaisDeUmNome } from "../../utils/string/string";
import Reply from "./Reply";
import NewReply from "./NewReply";
import ButtonLink from "../../components/ButtonLink";
import { formatTimer } from "../../utils/timer/timer";
import { Flex } from "../..";
import IconAction from "../../components/IconAction/IconAction";
import createDialogConfirm from "../../utils/dialogConfirm/createDialogConfirm";

ModalFeedbackLigacaoComment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    author: PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
    }).isRequired,
    text: PropTypes.string,
    time: PropTypes.number,
    replies: PropTypes.array,
    canRemove: PropTypes.bool,
    /**
     * Deve resultar em uma data válida quando passado para o construtor da classe Date.
     * new Date(createdAt)
     */
    createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.instanceOf(Date)]),
    /** Mostra um ícone indicando que o comentário foi lido */
    isRead: PropTypes.bool,
    /** Habilita o botão de responder ao comentário */
    canReply: PropTypes.bool,
    /** Habilita o botão de marcar como lido, a menos que isRead seja true */
    canCheckAsRead: PropTypes.bool,
  }),
  /** onInsertReply(id, replyText)  */
  onInsertReply: PropTypes.func,
  /** onClickCheckAsRead(id)  */
  onClickCheckAsRead: PropTypes.func,
  /** onRemoveComment(id)  */
  onRemoveComment: PropTypes.func,
  /** onRemoveReply(replyId) */
  onRemoveReply: PropTypes.func,
  setAudioPosition: PropTypes.func,
};

ModalFeedbackLigacaoComment.defaultProps = {
  comment: {
    text: "",
    time: null,
    createdAt: "",
    isRead: false,
    canRemove: false,
    canReply: false,
    canCheckAsRead: false,
  },
};

function ModalFeedbackLigacaoComment({
  comment,
  onInsertReply,
  onClickCheckAsRead,
  onRemoveComment,
  onRemoveReply,
  setAudioPosition,
  classes,
}) {
  const { id, author, text, time, createdAt, isRead, canRemove, canReply, canCheckAsRead, replies } = comment;

  if (!author || !id) {
    return null;
  }

  const [viewReplyInput, setViewReplyInput] = React.useState(false);
  const showCommentActions = !viewReplyInput && (canReply || canCheckAsRead);

  const intl = useIntl();

  const commentDate =
    !!createdAt &&
    intl.formatDate(moment(createdAt), {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

  const commentInfoLabel = `${author.name}, ${author.role} (${commentDate})`;

  return (
    <div className={classes.root}>
      <div className={classes.avatarWrapper}>
        <Avatar className={classes.avatar}>{getIniciaisDeUmNome(author.name)}</Avatar>
        {isRead && <DoneAllIcon color="action" titleAccess={intl.formatMessage({ defaultMessage: "Visualizado" })} />}
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.comment}>
          <Flex alignItems="center" justifyContent="space-between">
            <Typography color="textSecondary">{commentInfoLabel}</Typography>
            {canRemove && (
              <span style={{ fontSize: 20 }}>
                <IconAction
                  icon="delete"
                  title={intl.formatMessage({ defaultMessage: "Excluir" })}
                  onClick={() => {
                    createDialogConfirm({
                      title: intl.formatMessage({ defaultMessage: "Excluir Comentário" }),
                      text: intl.formatMessage({
                        defaultMessage: "Deseja realmente excluir o comentário?",
                      }),
                      callback: accepted => accepted && onRemoveComment(id),
                      acceptLabel: intl.formatMessage({ defaultMessage: "Excluir" }),
                      fullWidth: true,
                      maxWidth: "sm",
                    });
                  }}
                />
              </span>
            )}
          </Flex>

          <Typography className={classes.commentText}>
            {time > 0 && (
              <span className={classes.commentTime} onClick={() => setAudioPosition(time)}>
                {formatTimer(time)}
              </span>
            )}{" "}
            {text}
          </Typography>
        </div>
        {showCommentActions && (
          <div className={classes.commentActions}>
            {canReply && (
              <ButtonLink className={classes.replyButton} onClick={() => setViewReplyInput(true)}>
                <ReplyIcon />
                <FormattedMessage defaultMessage="Responder" description="Label do botão que responde um comentário" />
              </ButtonLink>
            )}
            {canCheckAsRead &&
              !isRead && (
                <ButtonLink onClick={() => onClickCheckAsRead(comment)}>
                  <Done />
                  <FormattedMessage
                    defaultMessage="Marcar como lido"
                    description="Label do botão que marca um comentário como lido"
                  />
                </ButtonLink>
              )}
          </div>
        )}

        {replies?.map((reply, i) => <Reply reply={reply} onRemove={onRemoveReply} key={i} />)}

        {viewReplyInput && (
          <NewReply
            onInsertReply={(replyText, onCancel) => onInsertReply(comment, replyText, onCancel)}
            onCancel={() => setViewReplyInput(false)}
          />
        )}
      </div>
    </div>
  );
}

export default withStyles(theme => ({
  root: {
    display: "flex",
    marginBottom: 24,
  },
  avatarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    margin: "0 10px 10px 10px",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  comment: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 8,
  },
  replyButton: {
    marginRight: 12,
  },
  commentText: {
    whiteSpace: "break-spaces",
  },
  commentActions: {
    marginTop: 6,
  },
  commentTime: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}))(ModalFeedbackLigacaoComment);
