import React from "react";
import PropTypes from "prop-types";
import { Typography, withStyles, Button } from "@material-ui/core";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { formatTimer } from "../../utils/timer/timer";

const messages = defineMessages({
  label: {
    defaultMessage: "Insira seu comentário:",
  },
  labelWithTime: {
    defaultMessage: "Insira seu comentário aos <b>{time}</b>:",
    description: "Exemplo: Insira seu comentário aos 4:17",
  },
});

ModalFeedbackLigacaoNewComment.propTypes = {
  time: PropTypes.number,
  disabled: PropTypes.bool,
  /** onInsertComment({ time, text }) */
  onInsertComment: PropTypes.func,
  setTime: PropTypes.func,
};

ModalFeedbackLigacaoNewComment.defaultProps = {
  time: "",
  disabled: false,
  setTime: () => {},
};

function ModalFeedbackLigacaoNewComment({ onInsertComment, time, setTime, disabled, classes }) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [text, setText] = React.useState("");

  const intl = useIntl();
  const label = time
    ? intl.formatMessage(messages.labelWithTime, { time: formatTimer(time) })
    : intl.formatMessage(messages.label);

  const reset = () => {
    setIsEditing(false);
    setText("");
    setTime(null);
  };

  return (
    <div>
      <Typography color="textSecondary">{label}</Typography>
      <textarea
        className={classes.textArea}
        rows="4"
        value={text}
        onChange={({ target }) => setText(target.value)}
        onFocus={() => setIsEditing(true)}
        maxLength={8000}
        disabled={disabled}
      />
      {isEditing &&
        !disabled && (
          <div className={classes.buttonsWrapper}>
            <Button size="small" onClick={reset}>
              <FormattedMessage defaultMessage="Cancelar" />
            </Button>
            <Button
              color="primary"
              size="small"
              disabled={!text}
              onClick={() => {
                onInsertComment({ time, text }, reset);
              }}
            >
              <FormattedMessage defaultMessage="Comentar" description="Label do botão que adiciona comentário" />
            </Button>
          </div>
        )}
    </div>
  );
}

export default withStyles({
  textArea: {
    width: "100%",
    resize: "vertical",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.46429em",
  },
  buttonsWrapper: {
    display: "flex",
    alignItems: "center",
  },
})(ModalFeedbackLigacaoNewComment);
