import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import { TIPO_FEEDBACK_LIGACAO } from "../../../_common/constantes";

AudioPlayerPoint.propTypes = {
  durationSeconds: PropTypes.number,
  item: PropTypes.object,
};

function AudioPlayerPoint({ item, durationSeconds, classes }) {
  const buildClass = param => {
    switch (param) {
      case TIPO_FEEDBACK_LIGACAO.SUMARIO:
        return classes.pointSumary;
      case TIPO_FEEDBACK_LIGACAO.COMENTARIO:
        return classes.pointComment;
      default:
        return classes.pointSumary;
    }
  };

  return (
    <span
      className={buildClass(item.type)}
      style={{
        left: `calc(${(item.timeMark / durationSeconds) * 100}% - ${
          item.type === TIPO_FEEDBACK_LIGACAO.SUMARIO ? 2 : 5
        }px)`,
      }}
    />
  );
}

export default withStyles(theme => ({
  pointSumary: {
    position: "absolute",
    height: 8,
    width: 8,
    borderRadius: "50%",
    border: "1px solid white",
    backgroundColor: theme.palette.primary.main,
    zIndex: 2,
  },
  pointComment: {
    position: "absolute",
    height: 14,
    width: 14,
    borderRadius: "50%",
    border: "1px solid white",
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1,
  },
}))(AudioPlayerPoint);
