import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useIntl } from "react-intl";
import { Avatar, Typography, withStyles } from "@material-ui/core";

import { getIniciaisDeUmNome } from "../../utils/string/string";
import { Flex } from "../..";
import IconAction from "../../components/IconAction/IconAction";
import createDialogConfirm from "../../utils/dialogConfirm/createDialogConfirm";

CommentReply.propTypes = {
  reply: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    author: PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
    }).isRequired,
    text: PropTypes.string,
    canRemove: PropTypes.bool,
    /**
     * Deve resultar em uma data válida quando passado para o construtor da classe Date.
     * new Date(createdAt)
     */
    createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.instanceOf(Date)]),
  }),
  onRemove: PropTypes.func,
};

CommentReply.defaultProps = {
  reply: {
    text: "",
    createdAt: "",
    canRemove: false,
  },
};

function CommentReply({ reply, onRemove, classes }) {
  const { id, author, text, createdAt, canRemove } = reply;

  if (!author || !id) {
    return null;
  }

  const intl = useIntl();

  const commentDate =
    !!createdAt &&
    intl.formatDate(moment(createdAt), {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

  const commentInfoLabel = `${author.name}, ${author.role} (${commentDate})`;

  return (
    <div className={classes.root}>
      <div className={classes.avatarWrapper}>
        <Avatar className={classes.avatar}>{getIniciaisDeUmNome(author.name)}</Avatar>
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.comment}>
          <Flex alignItems="center" justifyContent="space-between">
            <Typography color="textSecondary">{commentInfoLabel}</Typography>
            {canRemove && (
              <span style={{ fontSize: 20 }}>
                <IconAction
                  icon="delete"
                  title={intl.formatMessage({ defaultMessage: "Excluir" })}
                  onClick={() => {
                    createDialogConfirm({
                      title: intl.formatMessage({ defaultMessage: "Excluir Resposta" }),
                      text: intl.formatMessage({
                        defaultMessage: "Deseja realmente excluir a resposta?",
                      }),
                      callback: accepted => accepted && onRemove(reply),
                      acceptLabel: intl.formatMessage({ defaultMessage: "Excluir" }),
                      fullWidth: true,
                      maxWidth: "sm",
                    });
                  }}
                />
              </span>
            )}
          </Flex>

          <Typography className={classes.commentText}>{text}</Typography>
        </div>
      </div>
    </div>
  );
}

export default withStyles(theme => ({
  root: {
    display: "flex",
    marginTop: 8,
  },
  avatarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    margin: "0 10px 10px 10px",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  comment: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 8,
  },
  commentText: {
    whiteSpace: "break-spaces",
  },
}))(CommentReply);
