import React from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import { IconButton, Typography, withStyles } from "@material-ui/core";
import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import FastForwardIcon from "@material-ui/icons/FastForward";
import GetAppIcon from "@material-ui/icons/GetApp";

import Timer from "./Timer";
import Bar from "./Bar";
import useAudioPlayer from "./useAudioPlayer";

ModalFeedbackLigacaoAudioPlayer.propTypes = {
  source: PropTypes.string,
  /** onClickAddCommentAt(curTime:number) */
  onClickAddCommentAt: PropTypes.func,
  /* Habilita botão para adicionar comentário em um tempo específico */
  showAddCommentButton: PropTypes.bool,
  commentsTimeMarks: PropTypes.array,
  audioPosition: PropTypes.number,
  setAudioPosition: PropTypes.func,
  multipleTimeMarks: PropTypes.bool,
};

ModalFeedbackLigacaoAudioPlayer.defaultProps = {
  source: "",
};

function ModalFeedbackLigacaoAudioPlayer({
  source,
  onClickAddCommentAt,
  showAddCommentButton,
  commentsTimeMarks,
  audioPosition,
  setAudioPosition,
  multipleTimeMarks,
  classes,
}) {
  const intl = useIntl();

  const {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    playbackRate,
    incrementPlaybackRate,
    didLoad,
    hasError,
  } = useAudioPlayer();

  const disableFeatures = !didLoad || hasError;

  React.useEffect(
    () => {
      if (audioPosition) {
        setClickedTime(audioPosition);
        setTimeout(() => {
          setAudioPosition(null);
        });
      }
    },
    [audioPosition]
  );

  return (
    <div className={classes.root}>
      <audio id="feedbackLigacaoAudio">
        <source id="audioSourceElement" src={source} type="audio/mpeg" />
        <FormattedMessage defaultMessage=" O seu navegador não possui recurso de áudio necessário." />
      </audio>
      {!playing && (
        <IconButton
          title={intl.formatMessage({ defaultMessage: "Reproduzir" })}
          disabled={disableFeatures}
          onClick={() => {
            setPlaying(true);
            setAudioPosition(null);
          }}
        >
          <PlayIcon />
        </IconButton>
      )}
      {playing && (
        <IconButton
          title={intl.formatMessage({ defaultMessage: "Pausar" })}
          onClick={() => {
            setPlaying(false);
          }}
        >
          <PauseIcon />
        </IconButton>
      )}
      <IconButton
        title={intl.formatMessage({ defaultMessage: "Alterar velocidade" })}
        disabled={disableFeatures}
        onClick={incrementPlaybackRate}
      >
        <span className={classes.speedButton}>
          <FastForwardIcon fontSize="inherit" />
          <Typography variant="caption">{playbackRate}x</Typography>
        </span>
      </IconButton>
      <Timer duration={duration} curTime={curTime} />
      {!hasError &&
        !didLoad && (
          <p style={{ whiteSpace: "nowrap" }}>{intl.formatMessage({ defaultMessage: "Carregando áudio..." })}</p>
        )}
      {hasError && <p> {intl.formatMessage({ defaultMessage: "Não foi possível carregar o áudio da ligação." })}</p>}
      {!hasError && (
        <>
          <Bar
            disabled={disableFeatures}
            curTime={curTime}
            duration={duration}
            onTimeUpdate={time => setClickedTime(time)}
            showAddCommentButton={showAddCommentButton && (!playing && curTime > 0)}
            onClickAddCommentAt={onClickAddCommentAt}
            commentsTimeMarks={commentsTimeMarks}
            multipleTimeMarks={multipleTimeMarks}
          />
          <IconButton
            className={classes.downloadButton}
            title={intl.formatMessage({ defaultMessage: "Download" })}
            disabled={disableFeatures}
            onClick={() => window.open(source, "_blank")}
          >
            <GetAppIcon />
          </IconButton>
        </>
      )}
    </div>
  );
}

export default withStyles({
  root: {
    userSelect: "none",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  speedButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: 20,
  },
  downloadButton: {
    marginLeft: 8,
  },
})(ModalFeedbackLigacaoAudioPlayer);
