import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

import { StarRatingField } from "../components/StarRating";

const getHalf = value => Math.ceil(value / 2);

ModalFeedbackLigacaoRating.propTypes = {
  fields: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

function ModalFeedbackLigacaoRating({ fields, disabled, onChange }) {
  if (!fields || !fields.length) {
    return null;
  }

  const half = getHalf(fields.length);
  const firstCol = fields.slice(0, half);
  const secondCol = fields.slice(half);

  return (
    <Grid container spacing={32}>
      {[firstCol, secondCol].map((col, i) => (
        <Grid item xs={12} sm={6} key={i}>
          {col?.map((field, j) => (
            <div style={{ marginBottom: 8 }} key={j}>
              <StarRatingField
                label={field.descricao}
                rating={field.valor}
                onClickRating={newVal => onChange({ ...field, valor: newVal })}
                disabled={disabled}
                hasError={field.hasError}
              />
            </div>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}

export default ModalFeedbackLigacaoRating;
