import React from "react";
import PropTypes from "prop-types";
import { Typography, withStyles } from "@material-ui/core";
import { formatTimer, FORMAT_HOUR, FORMAT_MINUTES } from "../../utils/timer/timer";

const ONE_HOUR_IN_SECONDS = 3600;

AudioPlayerTimer.propTypes = {
  /** In seconds */
  duration: PropTypes.number,
  /** In seconds */
  curTime: PropTypes.number,
};

function AudioPlayerTimer({ duration, curTime, classes }) {
  const formatConfig = {
    format: duration >= ONE_HOUR_IN_SECONDS ? FORMAT_HOUR : FORMAT_MINUTES,
  };
  const formatAudioTimer = val => formatTimer(val, formatConfig);
  const audioPlaceholder = formatAudioTimer(null);

  return (
    <div className={classes.timer}>
      <Typography variant="caption" noWrap>
        {!duration && `${audioPlaceholder}/${audioPlaceholder}`}
        {duration && `${formatAudioTimer(curTime)}/${formatAudioTimer(duration)}`}
      </Typography>
    </div>
  );
}

export default withStyles({
  timer: {
    marginLeft: 8,
    marginRight: 20,
  },
})(AudioPlayerTimer);
