import { connect } from "react-redux";

import {
  onCloseModalFeedbackLigacao,
  selectFeedbackEditDraft,
  onInsertCommentRemote,
  onChangeRatingFieldRemote,
  onRemoveCommentRemote,
  selectCommentListEdit,
  onInsertReplyRemote,
  onCheckCommentAsReadRemote,
  onRemoveReplyRemote,
  selectCanChangeRating,
  solveFeedbackRemote,
  selectCanSolveFeedback,
} from "../../store/reducers/feedbackLigacao/reducer";
import ModalFeedbackLigacao from "./ModalFeedbackLigacao";
import { selectUsuario } from "../../store/reducers/usuario/selectors";

const mapStateToProps = (state, ownProps) => {
  const { ratingFields } = selectFeedbackEditDraft(state);
  const { isGerente } = selectUsuario(state); // TODO: em breve terá outros niveis de permissão

  return {
    ratingFields,
    commentList: selectCommentListEdit(state),
    disableRating: !selectCanChangeRating(state),
    disableNewComment: !isGerente,
    audioPlayerShowAddCommentButton: isGerente,
    showActions: false,
    canSolveFeedback: selectCanSolveFeedback(state),
    ...ownProps,
  };
};

const mapDispatchToProps = {
  onClose: onCloseModalFeedbackLigacao,
  onInsertComment: onInsertCommentRemote,
  onChangeRatingField: onChangeRatingFieldRemote,
  onRemoveComment: onRemoveCommentRemote,
  onInsertReply: onInsertReplyRemote,
  onClickCheckAsRead: onCheckCommentAsReadRemote,
  onRemoveReply: onRemoveReplyRemote,
  onClickSolveFeedback: solveFeedbackRemote,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalFeedbackLigacao);
