import { connect } from "react-redux";

import {
  onCloseModalFeedbackLigacao,
  selectModalFeedbackLigacao,
  selectShowAskForFeedback,
  selectFeedbackEditDraft,
  onSaveFeedback,
  selectAskedForFeedback,
  onInsertComment as onInsertCommentAction,
  onChangeRatingField as onChangeRatingFieldAction,
  onClickAskForFeedback as onClickAskForFeedbackAction,
  onRemoveComment as onRemoveCommentAction,
} from "../../store/reducers/feedbackLigacao/reducer";
import ModalFeedbackLigacao from "./ModalFeedbackLigacao";
import { selectUsuario } from "../../store/reducers/usuario/selectors";

const mapStateToProps = (state, ownProps) => {
  const { isTouched } = selectModalFeedbackLigacao(state);
  const { ratingFields, commentList } = selectFeedbackEditDraft(state);
  const { isGerente } = selectUsuario(state); // TODO: em breve terá outros niveis de permissão

  return {
    ratingFields,
    commentList,
    disableSave: !isGerente || !isTouched,
    disableRating: !isGerente,
    disableNewComment: !isGerente,
    audioPlayerShowAddCommentButton: isGerente,
    showAskForFeedback: selectShowAskForFeedback(state),
    askedForFeedback: selectAskedForFeedback(state),
    showActions: isGerente,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  onClose: onCloseModalFeedbackLigacao,
  onInsertComment: onInsertCommentAction,
  onChangeRatingField: onChangeRatingFieldAction,
  onSave: onSaveFeedback,
  onClickAskForFeedback: onClickAskForFeedbackAction,
  onRemoveComment: onRemoveCommentAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalFeedbackLigacao);
