import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ModalFeedbackLigacaoCreate from "./ModalFeedbackLigacaoCreate";
import ModalFeedbackLigacaoEdit from "./ModalFeedbackLigacaoEdit";
import {
  selectIsOpenModalFeedbackLigacao,
  selectIsEdit,
  selectFeedbackIsLoading,
  selectFeedbackAudioSource,
  onOpenModalFeedbackLigacao as onOpenModalFeedbackLigacaoAction,
} from "../../store/reducers/feedbackLigacao/reducer";

FeedbackLigacaoContainer.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOpenModalFeedbackLigacao: PropTypes.func.isRequired,
};

function FeedbackLigacaoContainer({ onOpenModalFeedbackLigacao, ...props }) {
  return (
    <div id="modalFeedbackLigacao">
      {(() => {
        if (!props.isOpen) {
          return null;
        }

        if (props.isEdit) {
          return <ModalFeedbackLigacaoEdit {...props} />;
        }

        return <ModalFeedbackLigacaoCreate {...props} />;
      })()}
    </div>
  );
}

const mapStateToProps = state => ({
  isEdit: selectIsEdit(state),
  isOpen: selectIsOpenModalFeedbackLigacao(state),
  isLoading: selectFeedbackIsLoading(state),
  audioSource: selectFeedbackAudioSource(state),
});

const mapDispatchToProps = {
  onOpenModalFeedbackLigacao: onOpenModalFeedbackLigacaoAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackLigacaoContainer);
