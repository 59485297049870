import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Typography, Link, withStyles } from "@material-ui/core";
import { normalizeLinkProtocol } from "../../_common/utils/string/string";

import { Flex } from "..";

InfoItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  /** Inverte label e value */
  inverted: PropTypes.bool,
  /** id aplicado no elemento wrapper do `value`. Util para testes. */
  id: PropTypes.string,
  link: PropTypes.bool,
  InfoComponent: PropTypes.elementType,
  InfoButton: PropTypes.elementType,
};

InfoItem.defaultProps = {
  className: "",
  label: "",
  /** Adicionado ao elemento de texto do `value` */
  value: "",
  /** Inverte label e value */
  inverted: false,
  id: undefined,
  InfoComponent: null,
};

function InfoItem({ className, label, value, inverted, id, link, classes, InfoComponent, InfoButton }) {
  const [open, setOpen] = useState(false);

  const handleInfoOpen = () => {
    setOpen(true);
  };

  const handleInfoClose = () => {
    setOpen(false);
  };

  return (
    <Flex flexDirection={inverted ? "column-reverse" : "column"} className={cn(classes.wrapper, className)}>
      <Typography variant="caption" color="textSecondary" className={classes.sideSpace}>
        {label}
      </Typography>
      {link ? (
        <Link className={classes.spaced} id={id} target="_blank" rel="noreferrer" href={normalizeLinkProtocol(value)}>
          {value ?? "-"}
        </Link>
      ) : (
        <Typography className={classes.spaced} id={id}>
          {value ?? "-"}
          {InfoComponent ? <InfoButton onClick={handleInfoOpen} /> : null}
        </Typography>
      )}
      {InfoComponent && open && <InfoComponent open={open} onClose={handleInfoClose} />}
    </Flex>
  );
}

export default withStyles({
  wrapper: { width: "100%", marginBottom: 12, wordBreak: "break-all" },
  spaced: {
    padding: "0 20px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  sideSpace: { paddingLeft: 20, paddingRight: 20 },
})(InfoItem);
