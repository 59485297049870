import { useState, useEffect, useCallback } from "react";

const AVAILABLE_PLAYBACK_RATES = [1, 1.5, 2];

function useAudioPlayer() {
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState();
  const [playbackRateIndex, setPlaybackRateIndex] = useState(0);
  const [didLoad, setDidLoad] = useState(false);
  const [hasError, setHasError] = useState(false);

  const incrementPlaybackRate = () => {
    setPlaybackRateIndex((playbackRateIndex + 1) % AVAILABLE_PLAYBACK_RATES.length);
  };

  const getPlaybackRate = useCallback(() => AVAILABLE_PLAYBACK_RATES[playbackRateIndex], [playbackRateIndex]);

  useEffect(
    () => {
      const audio = document.getElementById("feedbackLigacaoAudio");
      audio.playbackRate = getPlaybackRate();
    },
    [playbackRateIndex]
  );

  useEffect(() => {
    const audio = document.getElementById("feedbackLigacaoAudio");
    const sourceElement = document.getElementById("audioSourceElement");

    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
      setDidLoad(true);
    };

    const onErrorLoadSource = () => {
      setDidLoad(true);
      setHasError(true);
    };

    const setAudioTime = () => setCurTime(audio.currentTime);

    const onEnded = () => setPlaying(false);

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);
    audio.addEventListener("timeupdate", setAudioTime);
    audio.addEventListener("ended", onEnded);
    sourceElement.addEventListener("error", onErrorLoadSource);

    // React state listeners: update DOM on React state changes
    // eslint-disable-next-line
      if (playing) {
      audio.play();
    } else {
      audio.pause();
    }

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
      audio.removeEventListener("ended", onEnded);
      sourceElement.removeEventListener("error", onErrorLoadSource);
    };
  });

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    incrementPlaybackRate,
    playbackRate: getPlaybackRate(),
    didLoad,
    hasError,
  };
}

export default useAudioPlayer;
