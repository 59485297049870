import React from "react";
import PropTypes from "prop-types";
import { withStyles, Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

CommentNewReply.propTypes = {
  /** onInsertReply(text) */
  onInsertReply: PropTypes.func,
  onCancel: PropTypes.func,
};

function CommentNewReply({ onInsertReply, onCancel, classes }) {
  const [text, setText] = React.useState("");

  return (
    <div className={classes.root}>
      <textarea
        className={classes.textArea}
        rows="4"
        value={text}
        onChange={({ target }) => setText(target.value)}
        maxLength={8000}
      />
      <div className={classes.buttonsWrapper}>
        <Button
          size="small"
          onClick={() => {
            setText("");
            onCancel();
          }}
        >
          <FormattedMessage defaultMessage="Cancelar" />
        </Button>
        <Button color="primary" size="small" disabled={!text} onClick={() => onInsertReply(text, onCancel)}>
          <FormattedMessage defaultMessage="Responder" description="Label do botão que responde um comentário" />
        </Button>
      </div>
    </div>
  );
}

export default withStyles({
  root: {
    width: "100%",
    marginTop: 8,
  },
  textArea: {
    width: "100%",
    resize: "vertical",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.46429em",
  },
  buttonsWrapper: {
    display: "flex",
    alignItems: "center",
  },
})(CommentNewReply);
