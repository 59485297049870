import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";

import { Loading, Tipografia } from "..";
import DialogTitleClose from "../components/DialogTitleClose/DialogTitleClose";
import ButtonLink from "../components/ButtonLink";
import Rating from "./Rating";
import AudioPlayer from "./AudioPlayer";
import NewComment from "./NewComment";
import Comment from "./Comment";

ModalFeedbackLigacao.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  /* Desabilita o botão de salvar do modal */
  disableSave: PropTypes.bool,
  /* Mostra/Esconde as ações do Modal */
  showActions: PropTypes.bool,
  onClickAskForFeedback: PropTypes.func,
  showAskForFeedback: PropTypes.bool,
  askedForFeedback: PropTypes.bool,
  commentList: PropTypes.array,
  /** --  Children props -- */
  audioSource: PropTypes.string,
  ratingFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      descricao: PropTypes.string,
      valor: PropTypes.number,
    })
  ),
  onChangeRatingField: PropTypes.func,
  onInsertReply: PropTypes.func,
  onRemoveReply: PropTypes.func,
  onClickCheckAsRead: PropTypes.func,
  disableRating: PropTypes.bool,
  onInsertComment: PropTypes.func,
  disableNewComment: PropTypes.bool,
  audioPlayerShowAddCommentButton: PropTypes.bool,
  onRemoveComment: PropTypes.func,
  isEdit: PropTypes.bool,
  canSolveFeedback: PropTypes.bool,
  onClickSolveFeedback: PropTypes.func,
};

ModalFeedbackLigacao.defaultProps = {
  isLoading: false,
  disableSave: true,
  disableNewComment: true,
  showActions: true,
};

function ModalFeedbackLigacao({
  isEdit,
  showActions,
  isLoading,
  isOpen,
  onClose,
  onSave,
  disableSave,
  canSolveFeedback,
  onClickSolveFeedback,
  onClickAskForFeedback,
  onClickCheckAsRead,
  onRemoveComment,
  onInsertReply,
  onRemoveReply,
  audioSource,
  ratingFields,
  onChangeRatingField,
  disableRating,
  onInsertComment,
  disableNewComment,
  showAskForFeedback,
  askedForFeedback,
  commentList,
  audioPlayerShowAddCommentButton,
}) {
  const intl = useIntl();
  const [newCommentTime, setNewCommentTime] = React.useState(null);
  const commentsTimeMarks = commentList?.filter(({ time }) => !!time).map(({ time }) => time);
  const [audioPosition, setAudioPosition] = React.useState(0);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogTitleClose onClose={onClose}>
        <FormattedMessage defaultMessage="Feedback de Ligação" />
      </DialogTitleClose>
      <Loading isLoading={isLoading}>
        <DialogContent>
          <Rating fields={ratingFields} onChange={onChangeRatingField} disabled={disableRating} />

          <br />

          <AudioPlayer
            source={audioSource}
            showAddCommentButton={audioPlayerShowAddCommentButton}
            onClickAddCommentAt={time => setNewCommentTime(time)}
            commentsTimeMarks={commentsTimeMarks}
            audioPosition={audioPosition}
            setAudioPosition={setAudioPosition}
          />

          {!disableNewComment && (
            <NewComment time={newCommentTime} setTime={setNewCommentTime} onInsertComment={onInsertComment} />
          )}

          <br />

          {commentList?.map(comment => (
            <Comment
              comment={comment}
              onClickCheckAsRead={onClickCheckAsRead}
              onRemoveComment={onRemoveComment}
              onInsertReply={onInsertReply}
              onRemoveReply={onRemoveReply}
              setAudioPosition={setAudioPosition}
              key={comment.id}
            />
          ))}

          {isEdit &&
            disableNewComment &&
            commentList?.length <= 0 && (
              <div style={{ margin: "12px 0" }}>
                <Tipografia italico cor="darkSecondaryText">
                  {intl.formatMessage({ defaultMessage: "Nenhum comentário" })}
                </Tipografia>
              </div>
            )}

          <br />

          {showAskForFeedback && (
            <ButtonLink color="primary" size="small" onClick={onClickAskForFeedback} disabled={askedForFeedback}>
              {!askedForFeedback && <FormattedMessage defaultMessage="Pedir feedback ao gestor" />}
              {askedForFeedback && <FormattedMessage defaultMessage="Pedido de feedback encaminhado ao seu gestor" />}
            </ButtonLink>
          )}
        </DialogContent>
      </Loading>

      {showActions && (
        <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
          <Tipografia tipo="notaLegendas">
            <FormattedMessage
              defaultMessage="Apenas usuários com permissão para ouvir ligações terão acesso ao feedback de ligação.{br}Para ajustar as permissões <link>clique aqui</link>"
              values={{
                br: <br />,
                link: (...chunks) => (
                  <Tipografia
                    tipo="notaLegendas"
                    url="/spotter/equipe/prevendedor"
                    urlExterna
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </Tipografia>
                ),
              }}
            />
          </Tipografia>

          <div>
            <Button onClick={onClose}>
              {isEdit ? <FormattedMessage defaultMessage="Fechar" /> : <FormattedMessage defaultMessage="Cancelar" />}
            </Button>
            {onSave && (
              <Button color="primary" onClick={onSave} disabled={disableSave}>
                <FormattedMessage defaultMessage="Salvar" />
              </Button>
            )}
          </div>
        </DialogActions>
      )}

      {canSolveFeedback && (
        <DialogActions>
          <Button color="primary" onClick={onClickSolveFeedback}>
            {intl.formatMessage({ defaultMessage: "Marcar como resolvido" })}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default ModalFeedbackLigacao;
